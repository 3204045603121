const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://effective.dvgeo.app' : 'http://192.168.1.106:3039',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://effective.dvgeo.app' : 'http://192.168.1.106:3039',
    api: 'api/',
    apiSocket: 'effective::1.2',
    nameDir: 'effective',
    package: 'app.dvgeo.effective.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyC_hO1mdBPXVfHfjGhsAEasvcbgGze8C1k',
    appName: 'Effective VIP',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#00AFF0',
    colorDark: '#1A1A1A',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.effective.passenger',
    playStoreDriverId: 'app.dvgeo.effective.driver',
    appStorePassengerId: '6450400641',
    appStoreDriverId: '6450400805',
    email: "vipeffective@gmail.com",
};
export default config;
